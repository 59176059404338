// 所有接口请求
import axios from 'axios'
const apiUrl = 'https://www.daimaweb.top/tp/public/index.php/Activ';

// 请求菜单
export const getMenu = (param) => {
    return axios.request({
        url: '/permission/getMenu',
        method: 'post',
        data: param
    })
}
// 获得活动数据
export const getActiv = (params) => {
    return axios.get(apiUrl, { params });
  }
// 删除活动数据
export const deleteActiv = (id) => {
    return axios.delete(`${apiUrl}/${id}`)
      };
// 新增活动数据
export const createActiv = (data) => {
    return axios.post(apiUrl, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }
// 修改活动数据
export const updateActiv = (id, newData) => {
    return axios.put(`${apiUrl}/${id}`, newData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => {
        console.log(response);
        return response;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
//拿用户凭证数据
export const getCred = (params) => {
    return axios.request({
        url: 'http://phpms.com:81/Cred/login',
        method: 'post',
        params
    })
}
