<template>
  <el-form
    :model="form"
    status-icon
    :rules="rules"
    ref="form"
    label-width="100px"
    class="login-container"
  >
    <h3 class="login_title">系统登录</h3>
    <el-form-item
      label="用户名"
      label-width="100px"
      prop="username"
      class="username"
    >
      <el-input
        type="input"
        v-model="form.username"
        autocomplete="off"
        placeholder="请输入账号"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="密码"
      label-width="100px"
      prop="password"
      class="password"
    >
      <el-input
        type="password"
        v-model="form.password"
        autocomplete="off"
        placeholder="请输入密码"
      ></el-input>
    </el-form-item>
    <el-form-item class="login_submit">
      <el-button type="primary" @click="login" class="login_submit">登录</el-button>
    </el-form-item>
      <p>吃瓜的小站www.daimaweb.top备案编号（<a href="https://beian.miit.gov.cn">粤ICP备2021178391号）</a>，信息产业部备案管理系统网址（https://beian.miit.gov.cn ）</p>
  </el-form>
</template>

<script>
// import Mock from 'mockjs'
import {getMenu} from '../../api/data'
export default {
  name: "login",
  data() {
    return {
      form: {},
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            message: "用户名长度不能小于3位",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    login(){
      getMenu(this.form).then(({data:res})=>{
        console.log(res,'res');
        if(res.code == 20000){
          this.$store.commit('clearMenu')
          this.$store.commit('setMenu',res.data.menu)
          this.$store.commit('setToken',res.data.menu)
          this.$store.commit('setToken',this.$router)
          this.$router.push({name: 'home'})
        }else{
          this.$message.warning(res.data.message)
        }
      })
      // const token=Mock.random.guid()
      // this.$store.commit('setToken',token)
      // this.$router.push({name: 'home'})
    }
  }
};
</script>
<style lang="less" scoped>
.login-container{
    border-radius: 15px;
    background-clip: padding-box;
    margin: 180px auto;
    width: 350px;
    padding:35px 35px 15px 35px;
    background-color:#fff;
    border:1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
}

.login_title{
    margin: 0px auto 40px auto;
    text-align: center;
    color:#505458;
}
.login_submit{
    margin: 10px auto 0 auto;
}
</style>
